<template>
  <el-dialog
    title="中学生标准学术能力综合测试(THUSSAT)网站用户协议"
    :visible.sync="dialogVisible"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_group">
      <div class="info_item_header">一、 服务协议须知</div>
      <div class="info_item_txt">
        中学生标准学术能力综合测试(THUSSAT)网站的所有权归北京百世百诚教育科技有限公司所有，考生进行网上注册登录，则须同意所有服务条款。
      </div>
    </div>
    <div class="info_group">
      <div class="info_item_header">二、 考生申明</div>
      <ul class="info_item_txt">
        <li>1．考生保证所填写的资料准确详尽。</li>
        <li>2．考生保证不利用本网站从事任何违反法律、法规和政策规章的活动。</li>
        <li>
          3．考生保证不进行针对本网站的任何恶意行为。对于破坏本网站的行为将保留追究其法律责任。
        </li>
        <li>
          4．考生承诺已认真阅读本使用协议，并在点击"我已阅读并遵守"之后，即表示已与我方（北京百世百诚教育科技有限公司，下同）自愿达成本协议，并完全接受协议各条款的约束。
        </li>
        <li>
          5．考生承诺将仔细阅读本网站上指导考生操作的各种须知（如《考试详情》等），同意将须知中的内容作为本使用协议的附属条款加以遵守，并严格按照其要求进行操作。
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">三、 关于考生个人资料的隐私保护</div>
      <ul class="info_item_txt">
        <li>
          1．我方承诺尊重和保护考生个人资料的隐私，不对承办中学生标准学术能力测试(THUSSAT)考试服务机构和考生个人以外的社会公众公开其个人信息资料。但此承诺在出现下列情形时自动失效：
          <ul>
            <li>
              （1）考生（包括其授权的人）特别要求我方公开这些信息，并采用书面的形式提出上述要求。
            </li>
            <li>
              （2）在符合国家相应法律和政策条例的情况下，拥有合法调查权的国家机关索取考生个人信息。
            </li>
            <li>（3）对违规考生按相关规定公布其相关信息。</li>
            <li>
              （4）非我方过失，因考生自身操作原因或不可抗力的作用所导致的个人信息泄漏。
            </li>
          </ul>
        </li>
        <li>2．我方有权将考生个人信息提交相关单位进行验证和审核。</li>
        <li>
          3．我方因维护系统、组织考试、管理考籍等内部管理工作需要，可在相应的工作范围内使用并公开考生个人信息资料。
        </li>
        <li>
          4．在考试制度和考试软件业务允许范围内，考生有查阅和修改其个人信息的权利。
        </li>
        <li>
          5．如果考生提供的资料不真实、不准确，我方保留结束考生使用本系统服务的权利。
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">四、 关于网上报考支付方式</div>
      <ul class="info_item_txt">
        <li>1．考生完全接受我方在报考通知上所列服务项目的费用。</li>
        <li>
          2．考生同意接受以下支付方式及责任分担：
          <ul>
            <li>
              （1）考生必须按照我方各地方协会的要求进行网上支付，完成交费。。
            </li>
            <li>
              （2）我方通过 Internet
              网络将考生的电脑终端引导至微信支付的支付网关，从而实现网上支付。。
            </li>
            <li>
              （3）微信支付结算按微信支付服务协议的有关条款进行。我方只提供相应的链接服务，付费交易过程中出现的错误由考生与微信支付共同解决，我方对此不承担责任。
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">五、 关于服务条款的修改</div>
      <ul class="info_item_txt">
        <li>1．我方有权随时修改系统的服务条款和服务内容。</li>
        <li>
          2．系统服务条款及服务内容一旦发生变动，我方将会在页面上提示修改内容。如果考生不接受修改内容，则即时取消考生使用服务资格。考生要继续使用相关服务需要两方面的确认：
          <ul>
            <li>（1）首先确认系统服务条款及其变动。</li>
            <li>（2）同意接受所有的服务条款限制。</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">六、 关于考生密码和安全性</div>
      <ul class="info_item_txt">
        六、 关于考生密码和安全性
        <li>1．严禁考生擅自使用他人登录密码享受系统提供的服务。</li>
        <li>
          2．考生有妥善保管其登录密码的义务，因考生对自己的密码安全保管不善所造成的损失，由其本人负全部责任。
        </li>
        <li>3．考生若发现任何程序问题或安全漏洞情况，请立即通知我方。</li>
        <li>
          4．考生用于微信支付的密码系由微信提供，应仅属考生本人知晓，我方无权获知，我方亦不对其微信支付密码安全承担任何责任。
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">七、 关于结束服务</div>
      <ul class="info_item_txt">
        <li>
          1．我方有权随时中断服务，我方行使中断服务的权利不需对考生或第三方负责。
        </li>
        <li>
          2．考生若反对任何服务条款的建议或对后来的条款修改有异议，或对我方网络系统服务不满，仅享有以下的权利：
          <ul>
            <li>（1）不再使用我方网络系统提供的服务。</li>
            <li>
              （2）通知我方停止该考生的服务。我方不对考生自行终止结束服务的选择所产生的后果承担任何责任。
            </li>
          </ul>
        </li>
        <li>
          3．对违反本协议以及本协议附属条款的考生，我方有权利终止对其服务。
        </li>
        <li>
          4．结束服务后，考生使用与网上报名相关的一切服务的权利即刻中止，考生没有权利要求，我方也没有义务对其网上查询、报考负任何责任。
        </li>
      </ul>
    </div>
    <div class="info_group">
      <div class="info_item_header">八、 关于信息发布</div>
      <div class="info_item_txt">
        我方将通过网上通告的形式向考生发布提供权威资讯，告知服务条款的修改、服务变更或其它重要事项。
      </div>
    </div>
    <div class="info_group">
      <div class="info_item_header">九、 法律约束</div>
      <div class="info_item_txt">
        网上报考及查询等服务条款均要遵守国家的法律、法规，考生和我方一致同意服从有管辖权法院管辖。若我方部分服务条款与法律、法规相抵触，并不影响其他条款的法律效力。
      </div>
    </div>
    <div class="info_group">
      <div class="info_item_header">十、免责声明</div>
      <ul class="info_item_txt">
        <li>
          1．除百世百诚注明之服务协议外，其它因使用百世百诚而导致任何意外、疏忽、诽谤、版权等侵犯及其所造成的各种损失（包括因下载而感染电脑病毒），百世百诚概不负责，亦不承担任何法律责任。
        </li>
        <li>
          2．任何不通过百世百诚网页而链接得到的资讯、产品及服务，百世百诚概不负责，亦不负任何法律责任。
        </li>
        <li>
          3．百世百诚认为，一切网民在进入百世百诚报名流程时已经仔细看过本条款并完全同意。
          敬请谅解。
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="confirm" @click="confirm()">同意并继续</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ServiceInfo",
  props: {
    serviceDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.serviceDialogFu,
    };
  },
  methods: {
    confirm() {
      this.dialogVisible = false;
      this.$emit("closeServiceDialog", this.dialogVisible, true);
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeServiceDialog", this.dialogVisible, false);
    },
  },
  watch: {
    serviceDialogFu() {
      this.dialogVisible = this.serviceDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/dialog";
::v-deep .el-dialog {
  min-width: 800px;
  min-height: 500px;
  max-width: 85%;
  max-height: 70%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  .el-dialog__header{
      border-bottom: 1px solid #cccccc;
  }
  .el-dialog__body {
    overflow: auto;
    // padding: 0 48px 30px 48px;
    .info_group {
        margin-bottom: 20px;
      .info_item_header {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
      .info_item_txt {
        margin: 10px 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        text-indent: 2em;
      }
    }
  }
  .el-dialog__footer {
    padding: 30px 0;
    text-align: center;
    .el-button {
      width: 150px;
    }
  }
}
</style>
