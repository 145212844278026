<template>
    <div class="container">
        <el-main>
            <!-- 主干区域 -->
            <div class="content">
                <!-- 步骤条组件 -->
                <!-- <comm-step :active="2" :percentage="25" :type="6"></comm-step> -->
                <el-form
                        :model="stepForm"
                        :rules="stepFormRules"
                        ref="stepFormRef"
                        label-position="right"
                        label-width="100px"
                >
                    <el-form-item label="姓名：" prop="name">
                        <el-input
                                v-model="stepForm.name"
                                placeholder="请输入姓名"
                        ></el-input>
                        <div class="txt">
                            1-30位字符（中英文字符、数字、下划线、· 或 . ；若证件类型为居民身份证，姓名只能为中文）
                        </div>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password" ref="pwd">
                        <!-- @focus="isCheckIcon = false" -->
                        <el-input
                                v-model="stepForm.password"
                                placeholder="请输入密码"
                                maxlength="20"
                                type="password"
                                auto-complete="new-password"
                                @blur="isCheckIcon = true"
                                @focus="isCheckIcon = false"
                        ></el-input>
                        <div class="txt">6-20位字符，字母区分大小写</div>
                        <label class="strength" v-if="isShowType">
                            <i v-if="isCheckIcon" class="el-icon-check"></i>
                            <span class="f-fl
">安全程度：</span>
                            <b>
                                <i
                                        :style="{
                    'background-color': isShowType == 1 ? '#e0823d' : '#dbdbdb',
                  }"
                                >弱</i
                                >
                                <i
                                        :style="{
                    'background-color': isShowType == 2 ? '#e0823d' : '#dbdbdb',
                  }"
                                >中</i
                                >
                                <i
                                        :style="{
                    'background-color': isShowType == 3 ? '#e0823d' : '#dbdbdb',
                  }"
                                >强</i
                                >
                            </b>
                        </label>
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="rq_password">
                        <el-input
                                v-model="stepForm.rq_password"
                                placeholder="请输入确认密码"
                                maxlength="20"
                                type="password"
                                auto-complete="new-password"
                        ></el-input>
                        <div class="txt">两次输入的密码要一致</div>
                    </el-form-item>
                    <el-form-item label="证件类型：" prop="type">
                        <el-select
                                v-model="stepForm.type"
                                placeholder="请选择证件类型"
                                @change="onChangeCertType"
                        >
                            <el-option
                                    v-for="item in certificateTypeList"
                                    :key="item.value"
                                    :label="item.text"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件号码：" prop="sfzNum">
                        <el-input
                                v-model="stepForm.sfzNum"
                                placeholder="请输入证件号码"
                        ></el-input>
                        <div class="txt" v-if="stepForm.type==1">
                            须填写考生本人身份证号,若最后一位是"X",需要用大写字母
                        </div>
                        <div class="txt" v-if="stepForm.type==3">
                            须填写考生本人台胞证号,若含有括号,需要用中文括号
                        </div>
                    </el-form-item>
                    <el-form-item label="手机号：" prop="phone">
                        <el-input
                                v-model="stepForm.phone"
                                placeholder="请输入手机号"
                        ></el-input>
                        <div class="txt">用于接收申请信息、密码找回等信息， 非常重要。</div>
                    </el-form-item>
                    <el-form-item label="入学年份：" prop="start_school_date">
                        <el-select
                                v-model="stepForm.start_school_date"
                                placeholder="请选择入学年份"
                        >
                            <el-option
                                    v-for="item in years"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="就读中学："
                            class="ss city school"
                            prop="school_province"
                    >
                        <el-select
                                ref="school_province"
                                v-model="stepForm.school_province"
                                clearable
                                placeholder="省份"
                                :popper-append-to-body="false"
                                @change="changeProvince"
                        >
                            <el-option
                                    v-for="item in selectProvince"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            class="ss no_label school"
                            label=" "
                            prop="school_city"
                    >
                        <el-select
                                ref="school_city"
                                v-model="stepForm.school_city"
                                clearable
                                placeholder="城市"
                                :popper-append-to-body="false"
                                @change="changeCity"
                        >
                            <el-option
                                    v-for="item in selectCitySchool"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            class="ss no_label2"
                            label=" "
                            prop="school">
                        <el-select
                                filterable
                                ref="school"
                                v-model="stepForm.school"
                                clearable
                                placeholder="就读中学"
                                :popper-append-to-body="false"
                        >
                            <el-option
                                    v-for="item in selectSchoolList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-autocomplete
                      ref="school"
                      v-model="stepForm.school"
                      :fetch-suggestions="querySearchAsync"
                      placeholder="中学"
                      @select="handleSelect"
                    ></el-autocomplete> -->
                    </el-form-item>
                    <el-form-item label="邮箱：" prop="email">
                        <el-input
                                v-model="stepForm.email"
                                placeholder="请输入邮箱"
                        ></el-input>
                        <div class="txt">用于接收申请信息、密码找回等信息， 非常重要。</div>
                    </el-form-item>

                    <!--<el-form-item label="验证码：">
                        <el-input placeholder="请输入验证码" v-model="inputCode"></el-input>
                        验证码组件
                        <div @click="changCode()">
                            <s-identify :identifyCode="identifyCode"></s-identify>
                        </div>
                    </el-form-item>-->
                    <div class="agree">
                        <el-checkbox v-model="agreement">我已阅读并同意</el-checkbox>
                        <span class="txt" @click="toService">《用户服务协议》</span>
                    </div>
                    <el-form-item>
                        <el-button
                                :loading="loading"
                                :disabled="isDisable"
                                @click="confirm()"
                        >确 定
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </el-main>
        <dialog-box
                :dialogVisibleFu="dialogVisibleFu"
                :dialogFormFu="dialogFormFu"
                @closeDialog="closeDialog"
        ></dialog-box>
        <service-info
                :serviceDialogFu="serviceDialogFu"
                @closeServiceDialog="closeServiceDialog"
        ></service-info>
    </div>
</template>

<script>
    import commStep from "c/commStep";
    import SIdentify from "c/index/register/SIdentify";
    import DialogBox from "c/index/register/DialogBox";
    import ServiceInfo from "c/index/ServiceInfo";
    // 请求接口
    import {setAccountInfo, checkIdCard} from "r/index/register";
    import {getProvinceList, getCityList, getSchoolList} from "r/index/signUp";
    import {
        nameCheck,
        mobileCheck,
        mailCheck,
        sfzNumCheck,
        GANumCheck,
        TBNumCheck,
        PPNumCheck,
        checkStrong,
    } from "common/utils.js";

    export default {
        name: "StepOne",
        data() {
            const pwdCheck = async (rule, value, callback) => {
                if (value !== this.stepForm.password) {
                    callback(new Error("两次输入的密码不一致"));
                } else {
                    callback();
                }
            };
            const certCheck = async (rule, value, callback) => {
                if (this.stepForm.type == 1
                    && sfzNumCheck(value) === false) {
                    callback(new Error("请输入正确的身份证号"));
                // } else if (this.stepForm.type == 2
                //     && GANumCheck(value) === false) {
                //     callback(new Error("请输入正确的港澳通行证号码"));
                // } else if (this.stepForm.type == 3
                //     && TBNumCheck(value) === false) {
                //     callback(new Error("请输入正确的台胞证号码"));
                // } else if (this.stepForm.type == 4
                //     && PPNumCheck(value) === false) {
                //     callback(new Error("请输入正确的国内护照号码"));
                } else {
                    callback();
                }
            };
            return {
                identifyCode: Math.random().toString(36).substr(2).slice(1, 5), // 生成的验证码
                inputCode: "", // 输入的验证码
                isShowType: 0, // 控制密码强弱的显示（0-不显示，1-弱，2-中，3-强）
                isCheckIcon: false, // 控制密码icon显示隐藏
                stepForm: {
                    name: "",
                    password: "",
                    rq_password: "",
                    type: 1,
                    sfzNum: "",
                    phone: "",
                    email: "",
                    start_school_date: "",
                    school_province: "",
                    school_city: "",
                    school: "",
                },
                years: [], //近10年
                selectProvince: [], //省份
                CityName: {}, //根据省份筛选出所有城市的对象
                selectCitySchool: [], //就读中学-城市列表
                selectSchoolList: [], //就读中学-中学列表
                // 表单验证规则对象
                stepFormRules: {
                    // 对姓名进行校验
                    name: [
                        {required: true, message: "请输入姓名", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                if (nameCheck(value) === false) {
                                    callback(new Error("请输入正确的姓名格式"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 6, max: 20, message: "请输入正确的密码", trigger: "blur"},
                    ],
                    // 对密码进行校验
                    rq_password: [
                        {required: true, message: "请输入确认密码", trigger: "blur"},
                        {
                            validator: pwdCheck,
                            trigger: "blur",
                        },
                    ],
                    // 对证件类型进行校验
                    type: [
                        {required: true, message: "请选择证件类型", trigger: "change"},
                    ],
                    // 对证件号码进行校验
                    sfzNum: [
                        {required: true, message: "请输入证件号码", trigger: "blur"},
                        {
                            validator: certCheck,
                            trigger: "blur"
                        }
                    ],
                    // 对手机号进行校验
                    phone: [
                        {required: true, message: "请输入手机号", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                if (mobileCheck(value) === false) {
                                    callback(new Error("请输入正确的手机号"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    // 对邮箱进行校验
                    email: [
                        {required: true, message: "请输入邮箱", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                if (mailCheck(value) === false) {
                                    callback(new Error("邮箱格式不正确"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    // 对入学年分进行验证
                    start_school_date: [
                        {required: true, message: "请选择入学年份", trigger: "blur"},
                    ],
                    // 对学校进行验证
                    school_province: [
                        {
                            required: true,
                            message: "请选择所在学校省份！",
                            trigger: "change",
                        },
                    ],
                    school_city: [
                        {
                            required: true,
                            message: "请选择所在学校城市！",
                            trigger: "change",
                        },
                    ],
                    school: [
                        {required: true, message: "请选择所在学校！", trigger: "change"},
                    ],
                },
                dialogFormFu: {
                    title: "",
                    message: "",
                },
                dialogVisibleFu: false, // 控制提示弹框的显示与隐藏
                serviceDialogFu: false, // 控制用户服务协议弹框的显示与隐藏
                agreement: false,
                isDisable: true, // 禁用 false-不禁用，true-禁用
                loading: false,
                // 证件类型
                certificateTypeList: [
                    {
                        text: '居民身份证',
                        value: 1
                    },
                    {
                        text: '港澳通行证',
                        value: 2
                    },
                    {
                        text: '台胞证',
                        value: 3
                    },
                    {
                        text: '国内护照',
                        value: 4
                    },
                ]
            };
        },
        created() {
            this.$emit("getStatus", 2);

            if (this.agreement) {
                this.isDisable = false;
            } else {
                this.isDisable = true;
            }
            this.getTenYear();

            // if (localStorage.getItem("cityList")) {
            // this.selectProvince = JSON.parse(localStorage.getItem("cityList"));
            // } else {
            this.getProAndCity();

            this.getOldData();
            // }
        },
        methods: {
            onChangeCertType(val) {
                this.stepForm.type = val;
                this.stepForm.sfzNum = "";
                this.$refs.stepFormRef.clearValidate('sfzNum');
            },
            // 打开用户服务协议
            toService() {
                let href = window.location.href.replace("register/stepOne", "service");
                window.open(href, "_blank");
            },
            // 子组件触发，关闭用户服务协议弹框
            closeServiceDialog(val, type) {
                this.serviceDialogFu = val;
                if (type) {
                    return;
                } else {
                    this.$router.replace("/login");
                }
            },
            //回显用户已经填写过的值
            getOldData() {
                if (this.$route.query.applicationstatus == "0") {
                    this.stepForm.sfzNum = window.localStorage.getItem("sfzNum");
                    this.stepForm.name = window.localStorage.getItem("name");
                    this.stepForm.email = window.localStorage.getItem("email");
                    this.stepForm.sfzNum = window.localStorage.getItem("sfzNum");
                    this.stepForm.phone = window.localStorage.getItem("phone");

                    this.stepForm.school_province = Number(window.localStorage.getItem("school_province_id"));
                    this.changeProvince(this.stepForm.school_province);
                    this.stepForm.school_city = Number(window.localStorage.getItem("school_city_id"));
                    this.changeCity(this.stepForm.school_city);
                    this.stepForm.school = Number(window.localStorage.getItem("school_id"));
                    // this.changeProvince( window.localStorage.getItem("school_province_id"));
                    // console.log(window.localStorage.getItem("school_city_id"));
                    // this.changeCity();
                    // this.stepForm.school_province = window.localStorage.getItem("school_province");
                    // this.stepForm.school_city = window.localStorage.getItem("school_city");
                    // console.log("获取缓存的身份证");
                }
            },
            // 检查身份证是否存在
            async checkIdCard() {
                const {data: res} = await checkIdCard(this.stepForm.sfzNum);
                // console.log(res, "检查身份证是否存在");
                if (res.code == 400207) {
                    this.dialogFormFu.title = "提示";
                    this.dialogFormFu.message = res.message;
                    this.dialogVisibleFu = true;
                    return false;
                }
                return true;
            },
            // 子组件触发，关闭提示弹框
            closeDialog(value) {
                this.dialogVisibleFu = value;
            },
            //获取近10年的年份
            getTenYear() {
                let nowDate = new Date();
                let currentYear = nowDate.getFullYear()+1;
                let yearArr = [];
                for (let i = 0; i < 12; i++) {
                    let year = currentYear + 1 - i;
                    yearArr.push({name: year});
                }
                this.years = yearArr;
            },
            // 获取省份城市
            getProAndCity() {
                getProvinceList({}).then((res) => {
                    // console.log(res, "getProAndCity");
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    this.selectProvince = res.data.list;
                    // console.log(this.selectProvince);
                });


            },

            //选择籍贯省份change事件
            changeProvince(val) {
                this.stepForm.school_city = "";
                this.stepForm.school = "";
                this.selectCitySchool = [];
                this.selectSchoolList = [];
                this.selectCitySchool = this.getCurrentCityLists(val); //在此调用城市筛选列表
            },
            //返回当前省份下的城市列表
            getCurrentCityLists(val) {
                // console.log(val);
                let currentCity = [];
                if (
                    this.CityName == null ||
                    !this.CityName ||
                    Object.values(this.CityName).length == 0
                ) {
                    getCityList({})
                        .then((res) => {
                            // console.log(res, "getCity");
                            if (res.data.code === 200) {
                                // this.selectProvince = res.data.province_list;
                                this.CityName = res.data.list;
                                // console.log(this.CityName, "  this.CityName");
                                //   localStorage.setItem(
                                //       "cityList",
                                //       JSON.stringify(currentSchool)
                                //     );
                                for (let key in this.CityName) {
                                    if (this.CityName[key].parent_id == val) {
                                        currentCity.push(this.CityName[key]);
                                    }
                                }
                                return currentCity;
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message);
                        });
                } else {
                    for (let key in this.CityName) {
                        if (this.CityName[key].parent_id == val) {
                            currentCity.push(this.CityName[key]);
                        }
                    }
                    return currentCity;
                }
                return currentCity;
            },
            // 学校城市change事件
            changeCity(val) {
                this.stepForm.school = "";
                this.selectSchoolList = [];
                this.selectSchoolList = this.getCurrentSchoolLists(val);
            },

            // 返回当前城市下的学校列表
            getCurrentSchoolLists(val) {
                let currentSchool = [];
                getSchoolList({city_id: val})
                    .then((res) => {
                        // console.log(res);
                        if (res.data.code === 200) {
                            this.schoolName = res.data.list;
                            for (let key in this.schoolName) {
                                if (this.schoolName[key].city_id == val) {
                                    currentSchool.push(this.schoolName[key]);
                                }
                            }
                            return currentSchool;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message);
                    });
                return currentSchool;
            },
            // 根据身份证号，获取年龄
            getAge() {
                let UUserCard = this.stepForm.sfzNum;
                let myDate = new Date();
                let month = myDate.getMonth() + 1;
                let day = myDate.getDate();
                let age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
                if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
                    age++;
                }
                return age;
            },
            // 验证姓名为中文
            checkName() {
                let temp = this.stepForm.name;
                let re = /[^\u4e00-\u9fa5]/g;
                if (re.test(temp)) return false;
                return true;
            },
            // 提交 注册-第一步 信息
            async confirm() {
                if (
                    this.stepForm.sfzNum.slice(17, 18) == "x" ||
                    this.stepForm.sfzNum.slice(17, 18) == "X"
                ) {
                    this.stepForm.sfzNum = this.stepForm.sfzNum.slice(0, 17) + "X";
                }
                if (window.localStorage.getItem("applicationstatus") == 0) {
                    this.stepForm.is_perfect = 1;
                } else {
                    this.stepForm.is_perfect = 0;
                }
                this.$refs.stepFormRef.validate(async (valid) => {
                    // 验证姓名和密码是否符合规定
                    if (!valid) return;
                    // 该身份证已经注册过，阻止下一步操作
                    if (!(await this.checkIdCard())) return;
                    // if (this.inputCode != this.identifyCode) {
                    //     this.dialogFormFu.title = "提示";
                    //     this.dialogFormFu.message = "验证码错误";
                    //     return (this.dialogVisibleFu = true);
                    // }
                    if (this.stepForm.type == 1) {
                        let age = this.getAge();
                        let name = this.checkName();
                        if(!name){
                            this.$message.info('姓名应为你的中文真实姓名！')
                            return false;
                        }
                        if (age > 25) {
                            this.$message.info('请用考生身份证号进行注册！')
                            return false;
                        }
                    }
                    if (this.isDisable) {
                        return;
                    }
                    this.isDisable = true;
                    this.loading = true;
                    let obj = {};
                    for (let k in this.stepForm) {
                        if (k != 'school_province' && k != 'school_city') {
                            obj[k] = this.stepForm[k];
                        }
                    }
                    const {data: res} = await setAccountInfo(obj);
                    if (res.code !== 200) {
                        this.dialogFormFu.title = "提示";
                        this.dialogFormFu.message = res.message;
                        this.isDisable = false;
                        this.loading = false;
                        return (this.dialogVisibleFu = true);
                    }
                    this.isDisable = false;
                    this.loading = false;
                    this.$store.state.token = res.token;
                    // token存储
                    window.localStorage.setItem("index-token", res.token);
                    // 存储登录用户
                    window.localStorage.setItem("student_id", res.student_id);
                    window.localStorage.setItem("sfzNum", res.sfzNum);
                    window.localStorage.setItem("name", res.name);
                    window.localStorage.setItem("is_check_id_card", res.is_check_id_card);
                    window.localStorage.setItem("applicationstatus", res.applicationstatus);
                    await this.$router.replace({name: "examlist"});
                    // // 重置表单
                    // this.$refs.stepFormRef.resetFields();
                    //步骤跳转
                    // if (res.applicationstatus == 3) {
                    //   if (res.is_check_id_card == 0) {
                    //     // 使用编程式导航实现跳转
                    //     return this.$router.replace({name: "StepTwo"});
                    //   } else if (res.is_check_id_card == 1) {
                    //     // 使用编程式导航实现跳转
                    //     return this.$router.replace({name: "StepThree"});
                    //   }
                    // } else if (res.applicationstatus == 1) {
                    //   // 使用编程式导航实现跳转
                    //   return this.$router.replace({name: "StepFour"});
                    // }
                });
            },
            // 改变验证码
            changCode() {
                this.identifyCode = Math.random().toString(36).substr(2).slice(1, 5);
            },
        },
        watch: {
            "stepForm.password": function (newPwd) {
                this.isShowType = checkStrong(newPwd);
            },
            agreement(val) {
                this.isDisable = !val;
            },
        },
        components: {
            commStep,
            SIdentify,
            DialogBox,
            ServiceInfo,
        },
    };
</script>

<style scoped lang="scss">
    .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        // overflow: auto;
        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 20px 50px;

            .content {
                background-color: #ffffff;
                padding: 60px 60px 20px 60px;
            }
        }
    }

    ::v-deep .el-form {
        width: 690px;
        // background-color: yellow;
        /*margin: 50px auto;*/
        margin: 0 auto 50px;

        .el-form-item {
            position: relative;
            padding: 10px 0;
            margin: 0;

            .el-form-item__content {
                // width: 100%;
                position: relative;

                .el-input {
                    width: 370px;

                    .el-input__inner {
                        width: 370px;
                        height: 48px;
                        line-height: 50px;
                        padding: 0 10px;
                        border: 1px solid #d9d9d9;
                        font-size: 14px;
                        color: #4c4c4c;
                        overflow: hidden;
                    }
                }

                .txt {
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                    color: #999;
                }

                .el-form-item__error {
                    position: absolute;
                    left: 380px;
                    top: 25px;
                    transform: translate(0, -50%);
                    // width: 120px;
                }

                .el-button {
                    width: 310px;
                    height: 50px;
                    line-height: 50px;
                    padding: 0;
                    border: 0;
                    color: #ffffff;
                    background-color: #563279;
                    text-align: center;

                    &:hover {
                        background-color: #703a99;
                    }

                    &.is-disabled,
                    &.is-disabled:focus,
                    &.is-disabled:hover {
                        color: rgba(225, 225, 225, 0.6);
                        cursor: not-allowed;
                        background-image: none;
                        background-color: rgba(86, 50, 121, 0.6);
                        border-color: #ebeef5;
                    }
                }
            }

            .el-form-item__label {
                height: 50px;
                line-height: 50px;
                text-align: right;
                font-size: 14px;
                color: #4c4c4c;
            }

            &.no_label {
                .el-form-item__label {
                    width: 0 !important;
                    height: 0 !important;

                    &:before {
                        content: "" !important;
                    }
                }

                .el-form-item__content {
                    margin-left: 0 !important;
                }
            }

            &.no_label2 {
                .el-form-item__label {
                    &:before {
                        content: "" !important;
                    }
                }
            }

            &:last-child {
                text-align: center;
                // margin-top: 40px;

                .el-form-item__label {
                    width: 0;
                }

                .el-form-item__content {
                    // margin-left: 0 !important;
                }
            }

            .strength {
                color: #999;
                line-height: 30px;
                background-color: #fff;
                position: absolute;
                left: 380px;
                top: 25px;
                transform: translate(0, -50%);
                font-size: 12px;
                z-index: 100;

                .el-icon-check {
                    width: 20px;
                    text-align: center;
                    color: #563279;
                    font-size: 20px;
                    font-weight: bold;
                    vertical-align: middle;
                }

                .f-size12 {
                    margin-left: 20px;
                }

                b {
                    float: right;
                    width: 108px;
                    height: 16px;
                    overflow: hidden;
                    margin-top: 6px;
                    font-weight: 400;

                    i {
                        float: left;
                        font-style: normal;
                        font-size: 10px;
                        width: 34px;
                        height: 16px;
                        line-height: 16px;
                        background-color: #dbdbdb;
                        text-align: center;
                        margin-left: 1px;
                        color: #fff;
                    }
                }
            }

            &:nth-last-child(2) {
                .el-input {
                    width: 267px;

                    .el-input__inner {
                        width: 267px;
                    }
                }
            }

            .s-canvas {
                position: absolute;
                top: 25px;
                left: 270px;
                transform: translate(0, -50%);
            }
        }

        .ss .el-form-item__content .el-form-item__error {
            width: 120px !important;
            position: absolute;
            left: 0 !important;
            top: 50px !important;
            transform: translate(0, 0) !important;
        }

        .school {
            display: inline-block;
            margin-right: 10px;

            .el-form-item__content .el-input {
                width: 180px !important;
                padding-right: 35px !important;

                .el-input__inner {
                    width: 180px !important;
                    padding-right: 35px !important;
                }
            }
        }

        .agree {
            margin: 30px 0 0 100px;

            .el-checkbox {
                .el-checkbox__input {
                    .el-checkbox__inner {
                        border-color: #dcdfe6;
                    }

                    .el-checkbox__inner:hover {
                        border-color: #563279;
                    }
                }

                &.is-checked {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            background-color: #563279;
                            border-color: #563279;
                        }
                    }

                    .el-checkbox__label {
                        color: #563279;
                    }
                }
            }

            .txt {
                margin-left: 10px;
                color: #563279;
                font-weight: bold;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .el-form .el-form-item .el-form-item__content .el-input {
        width: 370px;
    }
</style>
